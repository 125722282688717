export class FormQuestionWrapperPlugin {
    static get pluginName() {
        return 'FormQuestionWrapper';
    }

    constructor(editor) {
        this.editor = editor;
    }

    init() {
        const editor = this.editor;

        // Register the custom element in the model schema.
        editor.model.schema.register('formQuestionWrapper', {
            isObject: true,
            isInline: true,
            allowWhere: '$text',
            allowContentOf: '$block',
            allowAttributes: ['data-tag']
        });

        // Define converters for upcasting and downcasting
        this.defineConverters();

        // Listen for input events and delete the element if editing starts within it
        this.setupDeleteOnEdit();
    }

    defineConverters() {
        const editor = this.editor;

        // Upcasting
        editor.conversion.for('upcast').elementToElement({
            view: {
                name: 'span',
                attributes: {
                    'data-tag': true
                }
            },
            model: (viewElement, { writer }) => {
                return writer.createElement('formQuestionWrapper', {
                    'data-tag': viewElement.getAttribute('data-tag')
                });
            }
        });

        // Downcasting
        editor.conversion.for('downcast').elementToElement({
            model: 'formQuestionWrapper',
            view: (modelElement, { writer }) => {
                const attributes = {
                    'data-tag': modelElement.getAttribute('data-tag')
                };
                return writer.createContainerElement('span', attributes);
            }
        });
    }

    setupDeleteOnEdit() {
        const editor = this.editor;

        editor.model.document.on('change:data', (evt, batch) => {
            // Get the current selection and position.
            const selection = editor.model.document.selection;
            const position = selection.getFirstPosition();
            const element = position.findAncestor('formQuestionWrapper');

            if (element) {
                evt.stop();  // This stops the event from further propagation.

                const currentUrl = window.location.href;

                // Check if the URL contains the specific part
                if (currentUrl.includes('/formulier-invullen/')) {
                    // Confirm before deleting the element
                    Swal.fire({
                        title: "Weet je het zeker?",
                        text: "Deze tekst is gekoppeld aan een functie die antwoorden inlaadt vanuit de opname. Als je deze tekst bewerkt, zal deze functie niet meer werken zoals bedoeld.",
                        icon: "info",
                        showCancelButton: true,
                        cancelButtonText: 'Annuleren',
                        cancelButtonColor: "#273146",
                        confirmButtonText: 'Doorgaan',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            editor.model.enqueueChange('transparent', writer => {
                                console.log('trigger?');
                                const contentRange = writer.createRangeIn(element);
                                const targetPosition = writer.createPositionBefore(element);
                                writer.move(contentRange, targetPosition);
                                writer.remove(element);
                            });
                        }
                    });
                } else {
                    // Directly delete the element without confirmation
                    editor.model.enqueueChange('transparent', writer => {
                        const contentRange = writer.createRangeIn(element);
                        const targetPosition = writer.createPositionBefore(element);
                        writer.move(contentRange, targetPosition);
                        writer.remove(element);
                    });
                }
            }
        });
    }
}
