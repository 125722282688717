// export class FormQuestionWrapperPlugin {
//     static get pluginName() {
//         return 'FormQuestionWrapper';
//     }
//
//     constructor(editor) {
//         this.editor = editor;
//     }
//
//     init() {
//         const editor = this.editor;
//
//         // Register the custom element in the model schema.
//         editor.model.schema.register('formQuestionWrapper', {
//             isObject: true,
//             isInline: true,
//             allowWhere: '$text',
//             allowContentOf: '$block',
//             allowAttributes: ['data-tag']
//         });
//
//         // Define converters for upcasting and downcasting
//         this.defineConverters();
//
//         // Listen for input events and delete the element if editing starts within it
//         this.setupDeleteOnEdit();
//     }
//
//     defineConverters() {
//         const editor = this.editor;
//
//         // Upcasting
//         editor.conversion.for('upcast').elementToElement({
//             view: {
//                 name: 'span',
//                 attributes: {
//                     'data-tag': true
//                 }
//             },
//             model: (viewElement, { writer }) => {
//                 return writer.createElement('formQuestionWrapper', {
//                     'data-tag': viewElement.getAttribute('data-tag')
//                 });
//             }
//         });
//
//         // Downcasting
//         editor.conversion.for('downcast').elementToElement({
//             model: 'formQuestionWrapper',
//             view: (modelElement, { writer }) => {
//                 const attributes = {
//                     'data-tag': modelElement.getAttribute('data-tag')
//                 };
//                 return writer.createContainerElement('span', attributes);
//             }
//         });
//     }
//
//     setupDeleteOnEdit() {
//         const editor = this.editor;
//
//         editor.model.document.on('change:data', (evt, batch) => {
//             // Get the current selection and position.
//             const selection = editor.model.document.selection;
//             const position = selection.getFirstPosition();
//             const element = position.findAncestor('formQuestionWrapper');
//
//             if (element) {
//                 evt.stop();  // This stops the event from further propagation.
//
//                 const currentUrl = window.location.href;
//
//                 // Check if the URL contains the specific part
//                 if (currentUrl.includes('/woningen/eigen-woningen/formulier-invullen/')) {
//                     // Confirm before deleting the element
//                     Swal.fire({
//                         title: "Weet je het zeker?",
//                         text: "Deze tekst is gekoppeld aan een functie die antwoorden inlaadt vanuit de opname. Als je deze tekst bewerkt, zal deze functie niet meer werken zoals bedoeld.",
//                         icon: "info",
//                         showCancelButton: true,
//                         cancelButtonText: 'Annuleren',
//                         cancelButtonColor: "#273146",
//                         confirmButtonText: 'Doorgaan',
//                     }).then((result) => {
//                         if (result.isConfirmed) {
//                             editor.model.enqueueChange('transparent', writer => {
//                                 const contentRange = writer.createRangeIn(element);
//                                 const targetPosition = writer.createPositionBefore(element);
//                                 writer.move(contentRange, targetPosition);
//                                 writer.remove(element);
//                             });
//                         }
//                     });
//                 } else {
//                     // Directly delete the element without confirmation
//                     editor.model.enqueueChange('transparent', writer => {
//                         const contentRange = writer.createRangeIn(element);
//                         const targetPosition = writer.createPositionBefore(element);
//                         writer.move(contentRange, targetPosition);
//                         writer.remove(element);
//                     });
//                 }
//             }
//         });
//     }
// }

export class FormQuestionWrapperPlugin {
    static get pluginName() {
        return 'FormQuestionWrapper';
    }

    constructor(editor) {
        this.editor = editor;
    }

    init() {
        const editor = this.editor;

        // Register the custom element in the model schema.
        editor.model.schema.register('formQuestionWrapper', {
            isObject: true,
            isInline: true,
            allowWhere: '$text',
            allowContentOf: '$block',
            allowAttributes: ['data-tag']
        });

        // Extend schema to allow strikethrough wrapping other elements
        editor.model.schema.extend('$text', { allowAttributes: 'strikethrough' });
        editor.model.schema.extend('formQuestionWrapper', { allowAttributes: 'strikethrough' });

        // Define converters for both custom span and strikethrough
        this.defineConverters();

        // Listen for input events and delete the element if editing starts within it
        this.setupDeleteOnEdit();

        // Override the strikethrough command inside the plugin
        this.overrideStrikethroughCommand();
    }

    defineConverters() {
        const editor = this.editor;

        // Upcasting: View to Model conversion for <span data-tag="...">
        editor.conversion.for('upcast').elementToElement({
            view: {
                name: 'span',
                attributes: {
                    'data-tag': true
                }
            },
            model: (viewElement, { writer }) => {
                return writer.createElement('formQuestionWrapper', {
                    'data-tag': viewElement.getAttribute('data-tag')
                });
            }
        });

        // Downcasting: Model to View conversion for formQuestionWrapper element
        editor.conversion.for('downcast').elementToElement({
            model: 'formQuestionWrapper',
            view: (modelElement, { writer }) => {
                const attributes = {
                    'data-tag': modelElement.getAttribute('data-tag')
                };
                return writer.createContainerElement('span', attributes);
            }
        });

        // Strikethrough Downcast: Ensure <s> wraps around <span> correctly
        editor.conversion.for('downcast').attributeToElement({
            model: 'strikethrough',
            view: (modelAttributeValue, { writer }) => {
                return writer.createAttributeElement('s', {}, { priority: 5 });
            },
            converterPriority: 'high'
        });

        // Strikethrough Upcast: Convert <s> tag back to strikethrough attribute
        editor.conversion.for('upcast').elementToAttribute({
            view: {
                name: 's',
                attributes: {
                    'data-tag': false // Ignore spans with data-tag attribute during upcast
                }
            },
            model: {
                key: 'strikethrough',
                value: true
            }
        });
    }

    setupDeleteOnEdit() {
        const editor = this.editor;

        editor.model.document.on('change:data', (evt, batch) => {
            // Get the current selection and position.
            const selection = editor.model.document.selection;
            const position = selection.getFirstPosition();
            const element = position.findAncestor('formQuestionWrapper');

            if (element) {
                evt.stop();  // This stops the event from further propagation.

                const currentUrl = window.location.href;

                // Check if the URL contains the specific part
                if (currentUrl.includes('/formulier-invullen/')) {
                    // Confirm before deleting the element
                    Swal.fire({
                        title: "Weet je het zeker?",
                        text: "Deze tekst is gekoppeld aan een functie die antwoorden inlaadt vanuit de opname. Als je deze tekst bewerkt, zal deze functie niet meer werken zoals bedoeld.",
                        icon: "info",
                        showCancelButton: true,
                        cancelButtonText: 'Annuleren',
                        cancelButtonColor: "#273146",
                        confirmButtonText: 'Doorgaan',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            editor.model.enqueueChange('transparent', writer => {
                                const contentRange = writer.createRangeIn(element);
                                const targetPosition = writer.createPositionBefore(element);
                                writer.move(contentRange, targetPosition);
                                writer.remove(element);
                            });
                        }
                    });
                } else {
                    // Directly delete the element without confirmation
                    editor.model.enqueueChange('transparent', writer => {
                        const contentRange = writer.createRangeIn(element);
                        const targetPosition = writer.createPositionBefore(element);
                        writer.move(contentRange, targetPosition);
                        writer.remove(element);
                    });
                }
            }
        });
    }

    // Override the strikethrough command directly within the plugin
    overrideStrikethroughCommand() {
        const editor = this.editor;

        // Get the default strikethrough command
        const strikethroughCommand = editor.commands.get('strikethrough');

        // Override its execute function
        strikethroughCommand.execute = () => {
            console.log('Strikethrough command executed!');

            const selection = editor.model.document.selection;
            const selectedElement = selection.getSelectedElement();

            editor.model.change(writer => {
                if (selectedElement && selectedElement.is('element', 'formQuestionWrapper')) {
                    // Handle formQuestionWrapper element
                    if (selectedElement.hasAttribute('strikethrough')) {
                        console.log('Removing strikethrough from formQuestionWrapper');
                        writer.removeAttribute('strikethrough', selectedElement);
                    } else {
                        console.log('Applying strikethrough to formQuestionWrapper');
                        writer.setAttribute('strikethrough', true, selectedElement);
                    }
                } else {
                    // Handle regular text range
                    const range = selection.getFirstRange();

                    // Check if strikethrough exists in the range and toggle it
                    let hasStrikethrough = false;
                    for (const item of range.getItems()) {
                        if (item.hasAttribute('strikethrough')) {
                            hasStrikethrough = true;
                            break;
                        }
                    }

                    if (hasStrikethrough) {
                        console.log('Removing strikethrough from text');
                        writer.removeAttribute('strikethrough', range);
                    } else {
                        console.log('Applying strikethrough to text');
                        writer.setAttribute('strikethrough', true, range);
                    }
                }
            });
        };
    }
}


